module controllers {
    export module manifest {
        interface IManifestCrewScope extends ng.IScope {
           
        }

        export class manifestSundryItemUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$state', "$stateParams", '$timeout', "$anchorScroll", 'bsLoadingOverlayService', "sundryItemService",
                "costModelElementService", "vatRateService", "currencyService", "classificationValueService", "unitOfMeasureService", "manifestSundryItemService"];

            breadCrumbDesc: string = "";
            selectedTab: number = 0;

            sundryItem: interfaces.manifest.IManifestSundryItem;

            constructor(private $scope: IManifestCrewScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private $stateParams: ng.ui.IStateParamsService,
                private $timeout: ng.ITimeoutService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private sundryItemService: interfaces.master.ISundryItemService,
                private costModelElementService: interfaces.costing.ICostModelElementService,
                private vatRateService: interfaces.master.IVatRateService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private manifestSundryItemService: interfaces.manifest.IManifestSundryItemService) {

                bsLoadingOverlayService.wrap({
                    referenceId: 'man.sun.update'
                },
                    () => {
                        return this.loadManSundryItem();
                    });
            }

            loadManSundryItem() {
                return this.manifestSundryItemService.get(this.$stateParams.msiId, this.$stateParams.manId).get((result) => {
                    this.sundryItem = result;

                    if (this.sundryItem.SundryItem) {
                        this.breadCrumbDesc = this.sundryItem.SequenceNumber + " - " + this.sundryItem.SundryItem.Display;
                    }
                    
                }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadSundryItems(searchText: string) {

                return this.sundryItemService.getDropdownList(this.sundryItem.OwnerEntityId,this.$stateParams.isInbound, searchText).query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadCostModelElement(searchText: string) {
                return this.costModelElementService.getDropdownList(this.sundryItem.OwnerEntityId).query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadVatRate(searchText: string) {
                return this.vatRateService.getDropdownList().query({ ownerEntityId: this.sundryItem.OwnerEntityId, countryId: this.sundryItem.LoadingCountryId }, () => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadApportionments() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CustomsApportionment
                }, () => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadUoMApportionments() {
                return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.sundryItem.OwnerEntityId }, () => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            sundryItemChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'man.sun.update'
                    },
                        () => {
                            return this.sundryItemService.getDefault().get({ sunId: model.Id, countryId: this.sundryItem.LoadingCountryId }, (result: interfaces.master.ISundryItemDefault) => {
                                this.sundryItem.SundryItemDescription = model.Description;
                                this.setDefaults(result);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            setDefaults(defaults: interfaces.master.ISundryItemDefault) {
                this.sundryItem.IsDutiable = defaults.Dutiable;
                this.sundryItem.IsIncludedInPrice = defaults.IsIncludedInPrice;
                this.sundryItem.ApportionmentClassificationValue = defaults.ApportionmentClassificationValue;
                this.sundryItem.ApportionmentUnitOfMeasure = defaults.ApportionmentUnitOfMeasure;
                this.sundryItem.VATRate = defaults.VatRateRate;
                this.sundryItem.VATRateRate = defaults.VatRate;
                this.sundryItem.CostModelElement = defaults.CostModelElement;

                this.calculateVatValue();
            }

            vatRateChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'man.sun.update'
                    },
                        () => {
                            return this.vatRateService.getVatRate().get({ mvrId: model.Id }, (vatRate: interfaces.master.IVatRate) => {
                                this.sundryItem.VATRate = vatRate.CurrentRate;
                                this.calculateVatValue();
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                } else {
                    this.sundryItem.VATRate = 0;
                    this.calculateVatValue();
                }
            }

            calculateSellingValue() {
                this.sundryItem.SellingValue = Number((this.sundryItem.Quantity * this.sundryItem.SellingPrice).toFixed(2));
                this.calculateVatValue();
            }

            calculateVatValue() {
                this.sundryItem.VATValue = this.sundryItem.SellingValue * this.sundryItem.VATRate / 100;
            }


            //Submit/Update


            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.sun.update'
                },
                    () => {
                        return this.manifestSundryItemService.save().save(this.sundryItem, (msgHandler) => {
                            this.generalService.displayMessageHandler(msgHandler);
                            this.$anchorScroll("topAnchor");

                            if (!msgHandler.HasErrorMessage) {
                                if (this.sundryItem.Id)
                                    this.loadManSundryItem();
                                else {
                                    this.$state.params["msiId"] = Number(msgHandler.ID);
                                    this.$state.params["isInbound"] = this.$stateParams.isInbound;
                                    this.$state.go('auth.Manifests.Update.SundryItem', this.$state.params, { reload: 'auth.Manifests.Update.SundryItem' });
                                }
                            }
                            
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            this.$anchorScroll("topAnchor");
                        }).$promise;
                    });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.sun.update'
                },
                    () => {
                        return this.manifestSundryItemService.save().save(this.sundryItem, (msgHandler: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(msgHandler);
                            if (!msgHandler.HasErrorMessage) {
                                this.$state.go("^");
                                this.$anchorScroll("topAnchor");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            Close() {
                this.$state.go("^");
            }

        };

        angular.module("app").controller("manifestSundryItemUpdateCtrl", controllers.manifest.manifestSundryItemUpdateCtrl);
    }
}